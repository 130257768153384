import roundNumber from '../roundNumber';

function displaySanctions(waypoints) {
  let maxSpeed = -1;
  let phoneUsageFlag = false;
  let speedingFlag = false;
  let speedLimit = 0;

  for (let i = 0; i < waypoints.length; i++) {
    const log = waypoints[i];

    // Handle max speed
    if (log.speed > maxSpeed) {
      maxSpeed = roundNumber(log.speed);
    }

    // Phone distraction flag
    if (log.distractions && !phoneUsageFlag) {
      phoneUsageFlag = true;
    }

    // Speeding
    if (log.speed > log.speedLimit) {
      speedingFlag = true;
      speedLimit = log.speedLimit;
    }
  }

  // Handle phoneUsage
  let phoneUsageText = '';

  if (phoneUsageFlag) {
    const packageWaypoint = waypoints.find((waypoint) =>
      waypoint.distractions?.some(
        (distraction) => distraction.packageName !== 'null'
      )
    );
    const packageName =
      packageWaypoint &&
      packageWaypoint.distractions.find(
        (distraction) => distraction.packageName !== 'null'
      ).name;

    phoneUsageText =
      `<div class="sanctions-box">` +
      `<p class="s-title">Uso de celular</p>` +
      `<p class="s-times">Aplicación: ${packageName}</p>` +
      `</div>`;
  }

  // Handle speeding
  let speedingText = '';
  if (speedingFlag) {
    speedingText =
      '<div class="sanctions-box">' +
      '<p class="s-title">Exceso de velocidad</p>' +
      `<p class="s-times">Máxima: ${roundNumber(maxSpeed)} km/h</p>` +
      `<p class="s-times">Límite: ${roundNumber(speedLimit)} km/h</p>` +
      '</div>';
  }

  return `${phoneUsageText}${speedingText}`;
}

export default displaySanctions;
