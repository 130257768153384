import React, { useEffect, useRef } from 'react';
import H from '@here/maps-api-for-javascript';
import displayTrip from '../../../utils/here-map/displayTrip';
// import { loadHereMapScripts } from './HereMapLoader';

const HereMap = ({ apikey, coordinates, trip, applyBoundingBox }) => {
  const mapUI = useRef(null);
  const tripGroup = useRef(null);
  const mapRef = useRef(null);
  const map = useRef(null);
  const platform = useRef(null);

  useEffect(() => {
    // Check if the map object has already been created
    if (!map.current) {
      // Create a platform object with the API key and useCIT option
      platform.current = new H.service.Platform({
        apikey
      });
      // Obtain the default map types from the platform object:
      const defaultLayers = platform.current.createDefaultLayers({
        pois: true
      });
      // Create a new map instance with the Tile layer, center and zoom level
      // Instantiate (and display) a map:
      const newMap = new H.Map(
        mapRef.current,
        defaultLayers.vector.normal.map,
        {
          zoom: 14,
          center: coordinates
        }
      );

      // Add panning and zooming behavior to the map
      // const behavior = new H.mapevents.Behavior(
      //   new H.mapevents.MapEvents(newMap)
      // );

      // add UI controls to the map
      const ui = H.ui.UI.createDefault(newMap, defaultLayers);
      mapUI.current = ui;

      // Set the map object to the reference
      map.current = newMap;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [apikey]);

  useEffect(() => {
    if (map && map.current) {
      map.current.setCenter(coordinates);
      map.current.setZoom(20);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [coordinates]);

  // Display a trip on the map
  useEffect(() => {
    displayTrip(
      H,
      map,
      mapUI,
      tripGroup,
      applyBoundingBox,
      trip,
      '#1eb1fc',
      undefined
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [trip]);

  // Return a div element to hold the map
  return (
    <div
      style={{
        width: '100%',
        height: '500px'
      }}
      ref={mapRef}
    />
  );
};

export default HereMap;
