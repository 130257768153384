import { useParams } from 'react-router-dom';
import axiosAuth from '../../../utils/axiosAuth';
import { useEffect, useRef, useState } from 'react';
import AirbagLoader from '../../common/airbag-loader/AirbagLoader';
import HereMap from './HereMap';
import Button from '../../common/button/Button';
import { useHistory } from 'react-router-dom';
import { TripInfo } from './TripInfo';
import Parameters from './Parameters';

export const Trip = () => {
  const history = useHistory();
  const { tripId } = useParams();
  const [trip, setTrip] = useState(null);
  const [parameters, setParameters] = useState(null);
  const [center, setCenter] = useState({
    lat: 19.419444444444,
    lng: -99.145555555556
  });
  const [isLoading, setIsLoading] = useState(true);
  const [isParametersOpen, setIsParametersOpen] = useState(false);
  const applyBoundingBox = useRef(true);

  const fetchTrip = async () => {
    try {
      const { data } = await axiosAuth.get(`/api/trips/trip/${tripId}`);
      setTrip(data.data.trip);
      setParameters(data.data.parameters);
      setCenter({
        lat: data.data.trip.tripPoints[0].latitude,
        lng: data.data.trip.tripPoints[0].longitude
      });
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchTrip();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleParametersClick = () => {
    setIsParametersOpen(!isParametersOpen);
  };

  const handleRedirectToTrips = () => {
    history.push('/viajes');
  };

  if (isLoading) {
    return <AirbagLoader />;
  }

  if (!trip) {
    return (
      <>
        <div>Viaje no encontrado</div>
        <Button variant="yellow-button" onClick={handleRedirectToTrips}>
          Regresar
        </Button>
      </>
    );
  }

  if (trip.tripPoints.length < 2) {
    return (
      <>
        <div>
          El viaje no cuenta con los suficientes puntos para mostrar mapa
        </div>
        <Button variant="yellow-button" onClick={handleRedirectToTrips}>
          Regresar
        </Button>
      </>
    );
  }

  return (
    <>
      <div className="trip-header">
        <h5 id="main-title">Viaje ID: {trip.id}</h5>
        <Button variant="yellow-button" onClick={handleRedirectToTrips}>
          Regresar
        </Button>
      </div>
      <TripInfo {...trip} />
      {isParametersOpen ? (
        <Parameters
          parameters={parameters}
          handleClose={handleParametersClick}
        />
      ) : (
        <p className="show-parameters" onClick={handleParametersClick}>
          Mostrar parámetros
        </p>
      )}
      <HereMap
        trip={trip}
        apikey={process.env.REACT_APP_HERE_MAPS_API_KEY}
        coordinates={center}
        applyBoundingBox={applyBoundingBox}
      />
    </>
  );
};
