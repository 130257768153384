import dayjs from 'dayjs';
import React from 'react';

const Parameters = ({ parameters, handleClose }) => {
  if (!parameters) {
    return <div className="no-data">No parameters data available.</div>;
  }

  return (
    <div className="parameters-container">
      <p className="close-parameters" onClick={handleClose}>
        Cerrar
      </p>

      <p className="heading">Parámetros</p>

      <div className="sections-container">
        <div className="section">
          <p>General</p>
          <p>ID: {parameters._id}</p>
          <p>
            Creado:
            {dayjs(parameters.created).format('DD/MM/YY LT')}
          </p>
          <p>
            Actualizado:
            {dayjs(parameters.updated).format('DD/MM/YY LT')}
          </p>
        </div>

        <div className="section">
          <p>Valores totales</p>
          <p>Aceleración: {parameters.aceleration_total_value}</p>
          <p>Frenado: {parameters.braking_total_value}</p>
          <p>Curvas: {parameters.cornering_total_value}</p>
          <p>Exceso de velocidad: {parameters.speeding_total_value}</p>
          <p>
            Tiempo de exceso de velocidad: {parameters.speeding_time.minutes}{' '}
            minutes
          </p>
        </div>

        <div className="section">
          <p>Límites de función</p>
          <p>
            Aceleración: LB = {parameters.acceleration_function.lb}, UB ={' '}
            {parameters.acceleration_function.ub}
          </p>
          <p>
            Frenado: LB = {parameters.braking_function.lb}, UB ={' '}
            {parameters.braking_function.ub}
          </p>
          <p>
            Curvas: LB = {parameters.cornering_function.lb}, UB ={' '}
            {parameters.cornering_function.ub}
          </p>
          <p>
            Exceso de velocidad: LB = {parameters.speeding_function.lb}, UB ={' '}
            {parameters.speeding_function.ub}
          </p>
        </div>
      </div>
    </div>
  );
};

export default Parameters;
