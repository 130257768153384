import dayjs from 'dayjs';
import durationToString from '../../../utils/durationToString';
import Stat from '../../common/stat/Stat';

function handleScoreColor(number) {
  if (number >= 80) {
    return 'green';
  } else if (number >= 60) {
    return 'yellow';
  } else if (number < 60) {
    return 'red';
  }
}

export const TripInfo = ({
  overall,
  acceleration,
  braking,
  cornering,
  speeding,
  endAt,
  startAt
}) => {
  const duration = dayjs(endAt).diff(dayjs(startAt), 'minutes');

  return (
    <div className="col s12">
      <div className="row" id="general-info">
        <div className="col s6 m6 l2 info-item">
          <div className="title">
            <Stat
              value={overall || '-'}
              color={handleScoreColor(overall || 0)}
            />
          </div>
        </div>

        <div className="col s6 m6 l2 info-item">
          <p className="title">
            {duration
              ? durationToString(dayjs.duration(Math.ceil(duration), 'minutes'))
              : '-'}
          </p>
          <p className="subtitle">Duración del viaje</p>
        </div>
        <div className="col s6 m6 l2 info-item">
          <p className="title">
            {acceleration ? Number.parseFloat(acceleration).toFixed(2) : '-'}
          </p>
          <p className="subtitle">Aceleración</p>
        </div>
        <div className="col s6 m6 l2 info-item">
          <p className="title">
            {braking ? Number.parseFloat(braking).toFixed(2) : '-'}
          </p>
          <p className="subtitle">Frenado</p>
        </div>
        <div className="col s6 m6 l2 info-item">
          <p className="title">
            {cornering ? Number.parseFloat(cornering).toFixed(2) : '-'}
          </p>
          <p className="subtitle">Curvas</p>
        </div>
        <div className="col s6 m6 l2 info-item">
          <p className="title">
            {speeding ? Number.parseFloat(speeding).toFixed(2) : '-'}
          </p>
          <p className="subtitle">Exceso de velocidad</p>
        </div>
      </div>
    </div>
  );
};
