import displayPolyline from './displayPolyline';
import displaySanctions from './displaySanctions';
import getMarker from './getMarker';

// Function to display a trip
function displayTrip(
  H,
  mapObj,
  mapUI,
  tripGroup,
  applyBoundingBox,
  trip,
  color,
  filters
) {
  // Declare map group
  const group = new H.map.Group();
  tripGroup.current = group;

  // Sanitize trip's location logs for polyline
  const waypoints = [];
  const distractions = [];

  if (trip && trip.tripPoints) {
    for (const tripPoint of trip.tripPoints) {
      waypoints.push({ lat: tripPoint.latitude, lng: tripPoint.longitude });
      if (tripPoint.distractions && tripPoint.distractions.length) {
        distractions.push({
          lat: tripPoint.latitude,
          lng: tripPoint.longitude,
          distractions: tripPoint.distractions
        });
      }
    }
  }

  // Display trip polyline
  displayPolyline(H, mapObj, mapUI, group, waypoints, color, 6, 1, null);

  if (trip.polylines) {
    const { midSpeeding, highSpeeding, phoneDistraction } = trip.polylines;

    if (phoneDistraction && phoneDistraction.length) {
      for (const section of phoneDistraction) {
        if (section.length > 1) {
          displayPolyline(
            H,
            mapObj,
            mapUI,
            group,
            section,
            '#015073',
            6,
            10,
            displaySanctions(section)
          );
          displayPolyline(H, mapObj, mapUI, group, section, color, 6, 2);
          // Add distraction marker
          const distractionIcon = new H.map.Icon(
            getMarker('phone_distraction'),
            {
              anchor: new H.math.Point(29, 85)
            }
          );

          const distractionMarker = new H.map.Marker(
            {
              lat: section[0].lat,
              lng: section[0].lng
            },
            { icon: distractionIcon }
          );
          group.addObject(distractionMarker);
        }
      }
    }

    if (midSpeeding && midSpeeding.length) {
      for (const section of midSpeeding) {
        if (section.length > 1) {
          displayPolyline(
            H,
            mapObj,
            mapUI,
            group,
            section,
            '#fdda0d',
            6,
            2,
            null
          );

          displayPolyline(
            H,
            mapObj,
            mapUI,
            group,
            section,
            '#fdda0d',
            6,
            2,
            displaySanctions(section)
          );
        }
      }
    }

    if (highSpeeding && highSpeeding.length) {
      for (const section of highSpeeding) {
        if (section.length > 1) {
          displayPolyline(
            H,
            mapObj,
            mapUI,
            group,
            section,
            '#e63228',
            6,
            2,
            displaySanctions(section)
          );
        }
      }
    }
  }

  // Add start marker
  const startIcon = new H.map.Icon(getMarker('start'), {
    anchor: new H.math.Point(29, 85)
  });

  const startMarker = new H.map.Marker(
    {
      lat: trip.tripPoints[trip.tripPoints.length - 1].latitude,
      lng: trip.tripPoints[trip.tripPoints.length - 1].longitude
    },
    { icon: startIcon }
  );
  group.addObject(startMarker);

  // Add end marker
  const endIcon = new H.map.Icon(getMarker('end'), {
    anchor: new H.math.Point(29, 85)
  });
  const endMarker = new H.map.Marker(
    {
      lat: trip.tripPoints[0].latitude,
      lng: trip.tripPoints[0].longitude
    },
    { icon: endIcon }
  );
  group.addObject(endMarker);

  // Add the group to the map
  mapObj.current.addObject(group);

  // Set minimum map zoom to fit all objects
  // Docs: https://developer.here.com/documentation/examples/maps-js/markers/zoom-to-set-of-markers
  if (applyBoundingBox) {
    mapObj.current.getViewModel().setLookAtData({
      bounds: group.getBoundingBox()
    });
  }
}

export default displayTrip;
