import axiosAuth from '../../../utils/axiosAuth';
import { useEffect, useState } from 'react';
import { TripRow } from './TripRow';
import './Trips.scss';
import AirbagLoader from '../../common/airbag-loader/AirbagLoader';
import Button from '../../common/button/Button';

export const Trips = () => {
  const [trips, setTrips] = useState([]);
  const [total, setTotal] = useState(1);
  const [isLoading, setIsLoading] = useState(true);
  const [page, setPage] = useState(1);

  const fetchTrips = async (pageNumber = 1) => {
    try {
      setIsLoading(true);
      const { data } = await axiosAuth.get(`/api/trips/${pageNumber}`);

      setTrips(data.data.trips[0]);
      setTotal(data.data.trips[1]);
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleFirstPage = () => {
    setPage(1);
    fetchTrips(1);
  };

  const handleLastPage = () => {
    setPage(Math.ceil(total / 10));
    fetchTrips(Math.ceil(total / 10));
  };

  const handlePrevPage = () => {
    if (page - 1 <= 0) {
      return;
    }
    setPage(page - 1);
    fetchTrips(page - 1);
  };

  const handleNextPage = () => {
    if (page + 1 > total / 10 + 1) {
      return;
    }
    setPage(page + 1);
    fetchTrips(page + 1);
  };

  useEffect(() => {
    fetchTrips();
  }, []);

  return (
    <div
      className="airbag-table-container trips-container"
      id="companies-table"
    >
      {isLoading ? (
        <AirbagLoader />
      ) : (
        <table className="airbag-table">
          <thead>
            <tr>
              <th>ID</th>
              <th className="center-align">Fecha</th>
              <th className="center-align">Trip Points</th>
              <th className="center-align">Calificación</th>
            </tr>
          </thead>
          <tbody>
            {trips.map((trip, idx) => (
              <TripRow {...trip} key={trip.id} idx={idx + 10 * (page - 1)} />
            ))}
          </tbody>
        </table>
      )}
      <div className="trips-button-container">
        <div>
          <Button
            disabled={page === 1}
            variant="yellow-button"
            onClick={handleFirstPage}
          >
            {'<<'}
          </Button>
          <Button
            disabled={page === 1}
            variant="yellow-button"
            onClick={handlePrevPage}
          >
            {'<'}
          </Button>
        </div>
        Página {page}
        <div>
          <Button variant="yellow-button" onClick={handleNextPage}>
            {'>'}
          </Button>
          <Button variant="yellow-button" onClick={handleLastPage}>
            {'>>'}
          </Button>
        </div>
      </div>
    </div>
  );
};
