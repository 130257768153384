import { Link } from 'react-router-dom';
import dayjs from 'dayjs';
import localizedFormat from 'dayjs/plugin/localizedFormat';
dayjs.extend(localizedFormat);

export const TripRow = ({ id, created, tripPoints, idx, overall }) => {
  return (
    <tr>
      <td>
        <span className="pos-index">{idx + 1}.</span>
        <Link to={`/viajes/${id}`}>{id}</Link>
      </td>
      <td className="center-align">{dayjs(created).format('DD/MM/YY LT')}</td>
      <td className="center-align">{tripPoints.length}</td>
      <td className="center-align">
        {overall ? Number.parseFloat(overall).toFixed(2) : '-'}
      </td>
    </tr>
  );
};
