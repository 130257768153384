import { library } from '@fortawesome/fontawesome-svg-core';
import {
  faUser,
  faLock,
  faHome,
  faCarCrash,
  faBuilding,
  faPlus,
  faBomb,
  faCaretRight,
  faCircle,
  faChevronDown,
  faChevronUp,
  faArrowLeft,
  faTimes,
  faUserTie,
  faCopy,
  faClock,
  faExternalLinkAlt,
  faUsers,
  faCog,
  faLaptop,
  faBook,
  faFileDownload,
  faUserSlash,
  faEnvelope,
  faCarSide
} from '@fortawesome/free-solid-svg-icons';

// Docs: https://github.com/FortAwesome/react-fontawesome

const setFontawesomeIcons = () => {
  library.add(
    faUser,
    faLock,
    faHome,
    faCarCrash,
    faBuilding,
    faPlus,
    faBomb,
    faCaretRight,
    faCircle,
    faChevronDown,
    faChevronUp,
    faArrowLeft,
    faTimes,
    faUserTie,
    faCopy,
    faClock,
    faExternalLinkAlt,
    faUsers,
    faCog,
    faLaptop,
    faBook,
    faFileDownload,
    faUserSlash,
    faEnvelope,
    faCarSide
  );
};

export default setFontawesomeIcons;
